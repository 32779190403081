<template>
  <div>
    <my-header
      title="Scoreboard Admin"
      :admin="admin"
      :logged-in="loggedIn"
      :username="username"
      @logout="$emit('logout')"
    />
    <div v-if="loggedIn && !admin">
      You need to be an admin to edit things.
    </div>
    <div v-if="loggedIn && admin" class="container mt-2">
      <ul class="nav nav-tabs mb-2">
        <li class="nav-item">
          <div
            class="nav-link clickable"
            :class="'' === openEvent ? 'active' : ''"
            @click="$emit('openEvent', '')"
          >
            General
          </div>
        </li>
        <li class="nav-item" v-for="(ev, id) in events">
          <div
            class="nav-link clickable"
            :class="id === openEvent ? 'active' : ''"
            @click="$emit('openEvent', id)"
          >
            {{ ev }}
          </div>
        </li>
      </ul>
      <div v-if="openEvent === ''">
        <h2>Admins</h2>
        <form class="form-inline" @submit.prevent="setAdmin">
          <input
            class="form-control mr-2"
            type="text"
            placeholder="new admin username"
            v-model="newAdminName"
          />
          <select class="form-control mr-2" v-model.number="newAdminValue">
            <option value="1">Add</option>
            <option value="0">Remove</option>
          </select>
          <button class="btn btn-secondary" type="submit">
            {{ newAdminValue === 1 ? "Add" : "Remove" }} Admin
          </button>
        </form>
        <h2 class="mt-2">Events</h2>
        <div>
          <ul class="list-group">
            <li class="list-group-item" v-for="(ev, id) in events">
              {{ ev }}
              <button class="btn btn-sm" @click="removeItem('events', id)">
                Remove
              </button>
            </li>
            <li class="list-group-item">
              <form class="form-inline" @submit.prevent="newEvent">
                <input
                  class="form-control mr-2"
                  type="text"
                  placeholder="new event name"
                  v-model="newEventName"
                />
                <button class="btn btn-secondary" type="submit">
                  Add Event
                </button>
              </form>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="openEvent !== ''">
        <ul class="nav nav-pills mb-3">
          <li class="nav-item">
            <div
              class="nav-link clickable"
              :class="'' === openContest ? 'active' : ''"
              @click="$emit('openContest', '')"
            >
              General
            </div>
          </li>
          <li class="nav-item" v-for="(contest, id) in contests">
            <div
              class="nav-link clickable"
              :class="id === openContest ? 'active' : ''"
              @click="$emit('openContest', id)"
            >
              {{ contest }}
            </div>
          </li>
        </ul>
        <div v-if="openContest === ''">
          <h2>Event Settings</h2>
          <div class="row">
            <div class="form-group mt-2 col">
              <label for="eventStatusInput">Event Status</label>
              <select
                class="form-control"
                id="eventStatusInput"
                v-model.number="eventInfo.status"
                @change="submitEventStatus"
              >
                <option value="0">Betting Open</option>
                <option value="1">Event Running</option>
                <option value="2">Event Finished</option>
              </select>
            </div>
            <div class="col">
              <div class="form-check mt-5">
                <input
                  class="form-check-input"
                  id="eventPublicInput"
                  type="checkbox"
                  v-model="eventInfo.public"
                  @change="submitEventStatus"
                />
                <label for="eventPublicInput" class="form-check-label"
                  >Public</label
                >
              </div>
            </div>
          </div>
          <h2>Contests</h2>
          <div>
            <ul class="list-group">
              <li class="list-group-item" v-for="(contest, id) in contests">
                {{ contest }}
                <button class="btn btn-sm" @click="removeItem('contests', id)">
                  Remove
                </button>
              </li>
              <li class="list-group-item">
                <form class="form-inline" @submit.prevent="newContest">
                  <input
                    class="form-control mr-2"
                    type="text"
                    placeholder="new contest name"
                    v-model="newContestName"
                  />
                  <button class="btn btn-secondary" type="submit">
                    Add Contest
                  </button>
                </form>
              </li>
            </ul>
          </div>
          <h2 class="mt-3">Participants</h2>
          <ul class="list-group">
            <li class="list-group-item" v-for="(part, id) in participants">
              <div class="form-inline">
                <input
                  class="form-control mr-2"
                  type="text"
                  v-model="part.name"
                  @input="setParticipant(id)"
                />
                <input
                  class="form-control mr-2"
                  type="text"
                  v-model="part.username"
                  @input="setParticipant(id)"
                />
                <button
                  class="btn btn-sm"
                  @click="removeItem('participants', id)"
                >
                  Remove
                </button>
              </div>
            </li>
            <li class="list-group-item">
              <form class="form-inline" @submit.prevent="newParticipant">
                <input
                  class="form-control mr-2"
                  type="text"
                  placeholder="name"
                  v-model="newParticipantName"
                />
                <input
                  class="form-control mr-2"
                  type="text"
                  placeholder="username"
                  v-model="newParticipantUsername"
                />
                <button class="btn btn-secondary" type="submit">Add</button>
              </form>
            </li>
          </ul>
        </div>
        <div v-if="openContest !== ''">
          <h2>Contest Settings</h2>
          <div class="alert alert-info">
            Sync URL: {{ syncBaseURL }}/api/sync/{{ openContest }}/{{
              contestInfo.syncKey
            }}/ <br />
            SyncKey: {{ synckey }}
            <span v-if="synckey === ''" @click="loadSyncKey">Show</span>
          </div>
          <div class="row">
            <div class="form-group mt-2 col">
              <label for="contestStatusInput">Contest Status</label>
              <select
                class="form-control"
                id="contestStatusInput"
                v-model.number="contestInfo.status"
                @change="submitContestStatus"
              >
                <option value="0">Betting Open</option>
                <option value="1">Contest Running</option>
                <option value="2">Contest Finished</option>
              </select>
            </div>
            <div class="col">
              <div class="form-check mt-5">
                <input
                  class="form-check-input"
                  id="contestPublicInput"
                  type="checkbox"
                  v-model="contestInfo.public"
                  @change="submitContestStatus"
                />
                <label for="contestPublicInput" class="form-check-label"
                  >Public</label
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group mt-2 col">
              <label for="contestFreezeTimeInput">Freeze Time</label>
              <input
                type="number"
                class="form-control"
                id="contestFreezeTimeInput"
                v-model.number="contestInfo.freezeTime"
                @input="submitContestStatus"
              />
            </div>
            <div class="form-group mt-2 col">
              <label for="contestFreezeTimePublicInput"
                >Public Freeze Time</label
              >
              <input
                type="number"
                class="form-control"
                id="contestFreezeTimePublicInput"
                v-model.number="contestInfo.freezeTimePublic"
                @input="submitContestStatus"
              />
            </div>
          </div>
          <h2>Tasks</h2>
          <div class="list-group">
            <div class="list-group-item" v-for="(task, id) in tasks">
              <div class="row">
                <div class="mb-1 col-3 form-group row">
                  <div class="col mr-3">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      :id="'taskname' + id"
                      v-model="task.name"
                      @input="submitTaskStatus(id)"
                    />
                  </div>
                </div>
                <div class="form-group mb-1 mt-2 col-3">
                  <input
                    class="form-check-input"
                    :id="'taskSubtaskmergingInput' + id"
                    type="checkbox"
                    v-model="task.subtaskmerging"
                    @change="submitTaskStatus(id)"
                  />
                  <label :for="'taskSubtaskmergingInput' + id" class="form-check-label"
                    >Subtask-Merging</label
                  >
                </div>
                <div class="mb-1 col-3 form-group row">
                  <label class="col col-form-label" :for="'taskGroupcount' + id"
                    >Group Count</label
                  >
                  <div class="col">
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      :id="'taskGroupcount' + id"
                      v-model.number="task.groupcount"
                      @input="submitTaskStatus(id)"
                    />
                  </div>
                </div>
                <div class="form-group mb-1 col-3">
                  <button class="btn btn-sm" @click="removeItem('tasks', id)">
                    Remove
                  </button>
                </div>
              </div>
            </div>
            <div class="list-group-item">
              <form class="form-inline" @submit.prevent="newTask">
                <input
                  class="form-control mr-2"
                  type="text"
                  placeholder="new task name"
                  v-model="newTaskName"
                />
                <button class="btn btn-secondary" type="submit">Add</button>
              </form>
            </div>
          </div>
          <h2 class="mt-3">Import Submissions</h2>
          <div class="mb-3">
            <form @submit.prevent="importSubmissions">
              <input
                class="form-control-file mb-2"
                id="import_file"
                type="file"
                accept=".json"
              />
              <button class="btn btn-secondary" type="submit">Import</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyHeader from "../components/my-header.vue";
import { common_props } from "../common";
import { fetchAPI, postAPI } from "../fetch-utils";

export default {
  components: { MyHeader },
  props: common_props,
  data: function () {
    return {
      newAdminName: "",
      newAdminValue: 1,
      newEventName: "",
      newContestName: "",
      newTaskName: "",
      newParticipantUsername: "",
      newParticipantName: "",
      synckey: "",
    };
  },
  computed: {
    syncBaseURL() {
      return location.protocol + "//" + location.host;
    },
  },
  watch: {
    openContest() {
      this.synckey = "";
    },
  },
  methods: {
    newEvent() {
      postAPI("/api/addevent/", { name: this.newEventName }).then((res) => {
        this.$emit("openEvent", "" + res);
        this.events["" + res] = this.newEventName;
        this.newEventName = "";
      });
    },
    newContest() {
      postAPI("/api/event/" + this.openEvent + "/addcontest/", {
        name: this.newContestName,
      }).then((res) => {
        this.$emit("openContest", "" + res);
        this.contests["" + res] = this.newContestName;
        this.newContestName = "";
      });
    },
    newParticipant() {
      if (this.newParticipantName === "") {
        this.newParticipantName = this.newParticipantUsername;
      }
      postAPI("/api/event/" + this.openEvent + "/addparticipant/", {
        username: this.newParticipantUsername,
        name: this.newParticipantName,
      }).then((res) => {
        this.participants[res] = {
          username: this.newParticipantUsername,
          name: this.newParticipantName,
        };
        this.newParticipantName = "";
        this.newParticipantUsername = "";
      });
    },
    setParticipant(id) {
      postAPI("/api/participant/" + id + "/set/", {
        username: this.participants[id].username,
        name: this.participants[id].name,
      });
    },
    newTask() {
      postAPI("/api/contest/" + this.openContest + "/addtask/", {
        name: this.newTaskName,
      }).then((res) => {
        this.tasks[res] = {
          name: this.newTaskName,
          subtaskmerging: false,
          groupcount: 0,
        };
        this.newTaskName = "";
      });
    },
    setAdmin() {
      postAPI("/api/setadmin/", {
        username: this.newAdminName,
        admin: this.newAdminValue,
      }).then((res) => {
        this.newAdminName = "";
      });
    },
    removeItem(table, id) {
      postAPI("/api/remove/" + table + "/", { id: id }).then((res) => {
        var capped = table.charAt(0).toUpperCase() + table.slice(1);
        this.$emit("load" + capped);
      });
      if (table === "contests" && id === this.openContest) {
        this.$emit("openContest", "");
      }
      if (table === "events" && id === this.openEvent) {
        this.$emit("openContest", "");
      }
    },
    submitEventStatus() {
      postAPI("/api/event/" + this.openEvent + "/setstatus/", this.eventInfo);
    },
    submitContestStatus() {
      postAPI("/api/contest/" + this.openContest + "/setstatus/", {
        public: this.contestInfo.public,
        status: this.contestInfo.status,
        freeze_time: this.contestInfo.freezeTime * 60,
        freeze_time_public: this.contestInfo.freezeTimePublic * 60,
      });
    },
    submitTaskStatus(id) {
      postAPI("/api/task/" + id + "/setstatus/", {
        name: this.tasks[id].name,
        subtaskmerging: this.tasks[id].subtaskmerging,
        groupcount: this.tasks[id].groupcount,
      });
    },
    loadSyncKey() {
      fetchAPI("/api/sync/synckey/" + this.openContest + "/").then((res) => {
        this.synckey = res;
      });
    },
    importSubmissions: function () {
      let reader = new FileReader();
      reader.onload = () => {
        var submissions = JSON.parse(reader.result);
        const convertTime = (time) => {
          var split = time.split(":").map((p) => parseInt(p));
          return 60 * (60 * split[0] + split[1]) + split[2];
        };
        fetchData("/api/sync/synckey/" + this.openContest + "/")
          .then((synckey) => {
            for (const sub of submissions) {
              if (sub.is_hidden) {
                continue;
              }
              const data = {
                task: sub.task,
                username: sub.user,
                internal_id: sub.id,
                abs_time: sub.timeunix,
                rel_time: sub.user_time_seconds,
                score: sub.score,
              };
              postData(
                "/api/sync/" +
                  this.openContest +
                  "/" +
                  this.contestInfo.syncKey +
                  "/add/",
                data,
                {
                  "X-SYNCKEY": synckey,
                }
              ).catch((res) => undefined);
            }
          })
          .then((res) => {
            document.getElementById("import_file").form.reset();
          });
      };
      const input = document.getElementById("import_file");
      if (input.files.length > 0) {
        reader.readAsText(input.files[0]);
      }
    },
  },
};
</script>
