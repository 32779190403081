var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "navbar bg-dark navbar-dark navbar-expand-sm header-bg" },
    [
      _c(
        "router-link",
        { staticClass: "navbar-brand flex-grow-1", attrs: { to: "/" } },
        [_vm._v("SOI " + _vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "navbar-nav mr-2" },
        [
          _vm.admin
            ? _c(
                "router-link",
                {
                  staticClass: "nav-item nav-link",
                  class: [_vm.title === "Scoreboard Admin" ? "active" : ""],
                  attrs: { to: "/admin" }
                },
                [_vm._v("Admin")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loggedIn
            ? _c(
                "router-link",
                {
                  staticClass: "nav-item nav-link",
                  class: [_vm.title === "Scoreboard Betting" ? "active" : ""],
                  attrs: { to: "/betting" }
                },
                [_vm._v("Betting")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "nav-item nav-link",
              class: [_vm.title === "Scoreboard" ? "active" : ""],
              attrs: { to: "/" }
            },
            [_vm._v("Scoreboard")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loggedIn
        ? _c("div", { staticClass: "navbar-text" }, [
            _vm._v("\n    Hi " + _vm._s(_vm.username) + " ("),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    return _vm.$emit("logout")
                  }
                }
              },
              [_vm._v("Logout")]
            ),
            _vm._v(")\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.loggedIn ? _c("div", [_vm._m(0)]) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "form",
      {
        staticClass: "form-inline",
        attrs: { method: "post", action: "/api/login" }
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-outline-light",
            attrs: { type: "submit" }
          },
          [_vm._v("\n        Login\n      ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }