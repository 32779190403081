var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("my-header", {
        attrs: {
          title: "Scoreboard Admin",
          admin: _vm.admin,
          "logged-in": _vm.loggedIn,
          username: _vm.username
        },
        on: {
          logout: function($event) {
            return _vm.$emit("logout")
          }
        }
      }),
      _vm._v(" "),
      _vm.loggedIn && !_vm.admin
        ? _c("div", [
            _vm._v("\n    You need to be an admin to edit things.\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loggedIn && _vm.admin
        ? _c("div", { staticClass: "container mt-2" }, [
            _c(
              "ul",
              { staticClass: "nav nav-tabs mb-2" },
              [
                _c("li", { staticClass: "nav-item" }, [
                  _c(
                    "div",
                    {
                      staticClass: "nav-link clickable",
                      class: "" === _vm.openEvent ? "active" : "",
                      on: {
                        click: function($event) {
                          return _vm.$emit("openEvent", "")
                        }
                      }
                    },
                    [_vm._v("\n          General\n        ")]
                  )
                ]),
                _vm._v(" "),
                _vm._l(_vm.events, function(ev, id) {
                  return _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "nav-link clickable",
                        class: id === _vm.openEvent ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.$emit("openEvent", id)
                          }
                        }
                      },
                      [_vm._v("\n          " + _vm._s(ev) + "\n        ")]
                    )
                  ])
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.openEvent === ""
              ? _c("div", [
                  _c("h2", [_vm._v("Admins")]),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      staticClass: "form-inline",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.setAdmin($event)
                        }
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newAdminName,
                            expression: "newAdminName"
                          }
                        ],
                        staticClass: "form-control mr-2",
                        attrs: {
                          type: "text",
                          placeholder: "new admin username"
                        },
                        domProps: { value: _vm.newAdminName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newAdminName = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.newAdminValue,
                              expression: "newAdminValue",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass: "form-control mr-2",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.newAdminValue = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "1" } }, [
                            _vm._v("Add")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "0" } }, [
                            _vm._v("Remove")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "submit" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.newAdminValue === 1 ? "Add" : "Remove"
                              ) +
                              " Admin\n        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("h2", { staticClass: "mt-2" }, [_vm._v("Events")]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "ul",
                      { staticClass: "list-group" },
                      [
                        _vm._l(_vm.events, function(ev, id) {
                          return _c("li", { staticClass: "list-group-item" }, [
                            _vm._v(
                              "\n            " + _vm._s(ev) + "\n            "
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-sm",
                                on: {
                                  click: function($event) {
                                    return _vm.removeItem("events", id)
                                  }
                                }
                              },
                              [_vm._v("\n              Remove\n            ")]
                            )
                          ])
                        }),
                        _vm._v(" "),
                        _c("li", { staticClass: "list-group-item" }, [
                          _c(
                            "form",
                            {
                              staticClass: "form-inline",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.newEvent($event)
                                }
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newEventName,
                                    expression: "newEventName"
                                  }
                                ],
                                staticClass: "form-control mr-2",
                                attrs: {
                                  type: "text",
                                  placeholder: "new event name"
                                },
                                domProps: { value: _vm.newEventName },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.newEventName = $event.target.value
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: { type: "submit" }
                                },
                                [
                                  _vm._v(
                                    "\n                Add Event\n              "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ],
                      2
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.openEvent !== ""
              ? _c("div", [
                  _c(
                    "ul",
                    { staticClass: "nav nav-pills mb-3" },
                    [
                      _c("li", { staticClass: "nav-item" }, [
                        _c(
                          "div",
                          {
                            staticClass: "nav-link clickable",
                            class: "" === _vm.openContest ? "active" : "",
                            on: {
                              click: function($event) {
                                return _vm.$emit("openContest", "")
                              }
                            }
                          },
                          [_vm._v("\n            General\n          ")]
                        )
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.contests, function(contest, id) {
                        return _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "div",
                            {
                              staticClass: "nav-link clickable",
                              class: id === _vm.openContest ? "active" : "",
                              on: {
                                click: function($event) {
                                  return _vm.$emit("openContest", id)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(contest) +
                                  "\n          "
                              )
                            ]
                          )
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.openContest === ""
                    ? _c("div", [
                        _c("h2", [_vm._v("Event Settings")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "form-group mt-2 col" }, [
                            _c(
                              "label",
                              { attrs: { for: "eventStatusInput" } },
                              [_vm._v("Event Status")]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.eventInfo.status,
                                    expression: "eventInfo.status",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { id: "eventStatusInput" },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return _vm._n(val)
                                        })
                                      _vm.$set(
                                        _vm.eventInfo,
                                        "status",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    _vm.submitEventStatus
                                  ]
                                }
                              },
                              [
                                _c("option", { attrs: { value: "0" } }, [
                                  _vm._v("Betting Open")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "1" } }, [
                                  _vm._v("Event Running")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "2" } }, [
                                  _vm._v("Event Finished")
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col" }, [
                            _c("div", { staticClass: "form-check mt-5" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.eventInfo.public,
                                    expression: "eventInfo.public"
                                  }
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  id: "eventPublicInput",
                                  type: "checkbox"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.eventInfo.public)
                                    ? _vm._i(_vm.eventInfo.public, null) > -1
                                    : _vm.eventInfo.public
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.eventInfo.public,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.eventInfo,
                                              "public",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.eventInfo,
                                              "public",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.eventInfo, "public", $$c)
                                      }
                                    },
                                    _vm.submitEventStatus
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "eventPublicInput" }
                                },
                                [_vm._v("Public")]
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("h2", [_vm._v("Contests")]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "ul",
                            { staticClass: "list-group" },
                            [
                              _vm._l(_vm.contests, function(contest, id) {
                                return _c(
                                  "li",
                                  { staticClass: "list-group-item" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(contest) +
                                        "\n              "
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm",
                                        on: {
                                          click: function($event) {
                                            return _vm.removeItem(
                                              "contests",
                                              id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Remove\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _c("li", { staticClass: "list-group-item" }, [
                                _c(
                                  "form",
                                  {
                                    staticClass: "form-inline",
                                    on: {
                                      submit: function($event) {
                                        $event.preventDefault()
                                        return _vm.newContest($event)
                                      }
                                    }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.newContestName,
                                          expression: "newContestName"
                                        }
                                      ],
                                      staticClass: "form-control mr-2",
                                      attrs: {
                                        type: "text",
                                        placeholder: "new contest name"
                                      },
                                      domProps: { value: _vm.newContestName },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.newContestName =
                                            $event.target.value
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-secondary",
                                        attrs: { type: "submit" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Add Contest\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c("h2", { staticClass: "mt-3" }, [
                          _vm._v("Participants")
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "list-group" },
                          [
                            _vm._l(_vm.participants, function(part, id) {
                              return _c(
                                "li",
                                { staticClass: "list-group-item" },
                                [
                                  _c("div", { staticClass: "form-inline" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: part.name,
                                          expression: "part.name"
                                        }
                                      ],
                                      staticClass: "form-control mr-2",
                                      attrs: { type: "text" },
                                      domProps: { value: part.name },
                                      on: {
                                        input: [
                                          function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              part,
                                              "name",
                                              $event.target.value
                                            )
                                          },
                                          function($event) {
                                            return _vm.setParticipant(id)
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: part.username,
                                          expression: "part.username"
                                        }
                                      ],
                                      staticClass: "form-control mr-2",
                                      attrs: { type: "text" },
                                      domProps: { value: part.username },
                                      on: {
                                        input: [
                                          function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              part,
                                              "username",
                                              $event.target.value
                                            )
                                          },
                                          function($event) {
                                            return _vm.setParticipant(id)
                                          }
                                        ]
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-sm",
                                        on: {
                                          click: function($event) {
                                            return _vm.removeItem(
                                              "participants",
                                              id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Remove\n              "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c("li", { staticClass: "list-group-item" }, [
                              _c(
                                "form",
                                {
                                  staticClass: "form-inline",
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.newParticipant($event)
                                    }
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newParticipantName,
                                        expression: "newParticipantName"
                                      }
                                    ],
                                    staticClass: "form-control mr-2",
                                    attrs: {
                                      type: "text",
                                      placeholder: "name"
                                    },
                                    domProps: { value: _vm.newParticipantName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.newParticipantName =
                                          $event.target.value
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newParticipantUsername,
                                        expression: "newParticipantUsername"
                                      }
                                    ],
                                    staticClass: "form-control mr-2",
                                    attrs: {
                                      type: "text",
                                      placeholder: "username"
                                    },
                                    domProps: {
                                      value: _vm.newParticipantUsername
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.newParticipantUsername =
                                          $event.target.value
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary",
                                      attrs: { type: "submit" }
                                    },
                                    [_vm._v("Add")]
                                  )
                                ]
                              )
                            ])
                          ],
                          2
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.openContest !== ""
                    ? _c("div", [
                        _c("h2", [_vm._v("Contest Settings")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "alert alert-info" }, [
                          _vm._v(
                            "\n          Sync URL: " +
                              _vm._s(_vm.syncBaseURL) +
                              "/api/sync/" +
                              _vm._s(_vm.openContest) +
                              "/" +
                              _vm._s(_vm.contestInfo.syncKey) +
                              "/ "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          SyncKey: " +
                              _vm._s(_vm.synckey) +
                              "\n          "
                          ),
                          _vm.synckey === ""
                            ? _c("span", { on: { click: _vm.loadSyncKey } }, [
                                _vm._v("Show")
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "form-group mt-2 col" }, [
                            _c(
                              "label",
                              { attrs: { for: "contestStatusInput" } },
                              [_vm._v("Contest Status")]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.contestInfo.status,
                                    expression: "contestInfo.status",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { id: "contestStatusInput" },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return _vm._n(val)
                                        })
                                      _vm.$set(
                                        _vm.contestInfo,
                                        "status",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    _vm.submitContestStatus
                                  ]
                                }
                              },
                              [
                                _c("option", { attrs: { value: "0" } }, [
                                  _vm._v("Betting Open")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "1" } }, [
                                  _vm._v("Contest Running")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "2" } }, [
                                  _vm._v("Contest Finished")
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col" }, [
                            _c("div", { staticClass: "form-check mt-5" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.contestInfo.public,
                                    expression: "contestInfo.public"
                                  }
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  id: "contestPublicInput",
                                  type: "checkbox"
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.contestInfo.public)
                                    ? _vm._i(_vm.contestInfo.public, null) > -1
                                    : _vm.contestInfo.public
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.contestInfo.public,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.contestInfo,
                                              "public",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.contestInfo,
                                              "public",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.contestInfo, "public", $$c)
                                      }
                                    },
                                    _vm.submitContestStatus
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "contestPublicInput" }
                                },
                                [_vm._v("Public")]
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "form-group mt-2 col" }, [
                            _c(
                              "label",
                              { attrs: { for: "contestFreezeTimeInput" } },
                              [_vm._v("Freeze Time")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.contestInfo.freezeTime,
                                  expression: "contestInfo.freezeTime",
                                  modifiers: { number: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                id: "contestFreezeTimeInput"
                              },
                              domProps: { value: _vm.contestInfo.freezeTime },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.contestInfo,
                                      "freezeTime",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  _vm.submitContestStatus
                                ],
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group mt-2 col" }, [
                            _c(
                              "label",
                              {
                                attrs: { for: "contestFreezeTimePublicInput" }
                              },
                              [_vm._v("Public Freeze Time")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.contestInfo.freezeTimePublic,
                                  expression: "contestInfo.freezeTimePublic",
                                  modifiers: { number: true }
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                id: "contestFreezeTimePublicInput"
                              },
                              domProps: {
                                value: _vm.contestInfo.freezeTimePublic
                              },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.contestInfo,
                                      "freezeTimePublic",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  _vm.submitContestStatus
                                ],
                                blur: function($event) {
                                  return _vm.$forceUpdate()
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("h2", [_vm._v("Tasks")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "list-group" },
                          [
                            _vm._l(_vm.tasks, function(task, id) {
                              return _c(
                                "div",
                                { staticClass: "list-group-item" },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mb-1 col-3 form-group row"
                                      },
                                      [
                                        _c("div", { staticClass: "col mr-3" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: task.name,
                                                expression: "task.name"
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              type: "text",
                                              id: "taskname" + id
                                            },
                                            domProps: { value: task.name },
                                            on: {
                                              input: [
                                                function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    task,
                                                    "name",
                                                    $event.target.value
                                                  )
                                                },
                                                function($event) {
                                                  return _vm.submitTaskStatus(
                                                    id
                                                  )
                                                }
                                              ]
                                            }
                                          })
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-group mb-1 mt-2 col-3"
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: task.subtaskmerging,
                                              expression: "task.subtaskmerging"
                                            }
                                          ],
                                          staticClass: "form-check-input",
                                          attrs: {
                                            id: "taskSubtaskmergingInput" + id,
                                            type: "checkbox"
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              task.subtaskmerging
                                            )
                                              ? _vm._i(
                                                  task.subtaskmerging,
                                                  null
                                                ) > -1
                                              : task.subtaskmerging
                                          },
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$a = task.subtaskmerging,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        task,
                                                        "subtaskmerging",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        task,
                                                        "subtaskmerging",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    task,
                                                    "subtaskmerging",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function($event) {
                                                return _vm.submitTaskStatus(id)
                                              }
                                            ]
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "form-check-label",
                                            attrs: {
                                              for:
                                                "taskSubtaskmergingInput" + id
                                            }
                                          },
                                          [_vm._v("Subtask-Merging")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mb-1 col-3 form-group row"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "col col-form-label",
                                            attrs: {
                                              for: "taskGroupcount" + id
                                            }
                                          },
                                          [_vm._v("Group Count")]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: task.groupcount,
                                                expression: "task.groupcount",
                                                modifiers: { number: true }
                                              }
                                            ],
                                            staticClass:
                                              "form-control form-control-sm",
                                            attrs: {
                                              type: "number",
                                              id: "taskGroupcount" + id
                                            },
                                            domProps: {
                                              value: task.groupcount
                                            },
                                            on: {
                                              input: [
                                                function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    task,
                                                    "groupcount",
                                                    _vm._n($event.target.value)
                                                  )
                                                },
                                                function($event) {
                                                  return _vm.submitTaskStatus(
                                                    id
                                                  )
                                                }
                                              ],
                                              blur: function($event) {
                                                return _vm.$forceUpdate()
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group mb-1 col-3" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-sm",
                                            on: {
                                              click: function($event) {
                                                return _vm.removeItem(
                                                  "tasks",
                                                  id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  Remove\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "list-group-item" }, [
                              _c(
                                "form",
                                {
                                  staticClass: "form-inline",
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.newTask($event)
                                    }
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.newTaskName,
                                        expression: "newTaskName"
                                      }
                                    ],
                                    staticClass: "form-control mr-2",
                                    attrs: {
                                      type: "text",
                                      placeholder: "new task name"
                                    },
                                    domProps: { value: _vm.newTaskName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.newTaskName = $event.target.value
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-secondary",
                                      attrs: { type: "submit" }
                                    },
                                    [_vm._v("Add")]
                                  )
                                ]
                              )
                            ])
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("h2", { staticClass: "mt-3" }, [
                          _vm._v("Import Submissions")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mb-3" }, [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.importSubmissions($event)
                                }
                              }
                            },
                            [
                              _c("input", {
                                staticClass: "form-control-file mb-2",
                                attrs: {
                                  id: "import_file",
                                  type: "file",
                                  accept: ".json"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: { type: "submit" }
                                },
                                [_vm._v("Import")]
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }