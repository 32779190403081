var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("my-header", {
        attrs: {
          title: "Scoreboard",
          admin: _vm.admin,
          "logged-in": _vm.loggedIn,
          username: _vm.username
        },
        on: {
          logout: function($event) {
            return _vm.$emit("logout")
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container mt-2" }, [
        _c(
          "ul",
          { staticClass: "nav nav-tabs mb-2" },
          _vm._l(_vm.events, function(ev, id) {
            return _c("li", { staticClass: "nav-item" }, [
              _c(
                "div",
                {
                  staticClass: "nav-link clickable",
                  class: id === _vm.openEvent ? "active" : "",
                  on: {
                    click: function($event) {
                      return _vm.$emit("openEvent", id)
                    }
                  }
                },
                [_vm._v("\n          " + _vm._s(ev) + "\n        ")]
              )
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm.openEvent !== ""
          ? _c("div", [
              _c(
                "ul",
                { staticClass: "nav nav-pills mb-3" },
                [
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "nav-link clickable",
                        class: "" === _vm.openContest ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.$emit("openContest", "")
                          }
                        }
                      },
                      [_vm._v("\n            Overview\n          ")]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.contests, function(contest, id) {
                    return _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "nav-link clickable",
                          class: id === _vm.openContest ? "active" : "",
                          on: {
                            click: function($event) {
                              return _vm.$emit("openContest", id)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(contest) + "\n          "
                          )
                        ]
                      )
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("div", [
                _c("h2", [
                  _vm._v(
                    "\n          Ranking " +
                      _vm._s(_vm.events[_vm.openEvent]) +
                      "\n          " +
                      _vm._s(
                        _vm.openContest !== ""
                          ? _vm.contests[_vm.openContest]
                          : ""
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _vm.openContest !== "" && _vm.contestInfo.currentRelTime
                  ? _c(
                      "div",
                      { staticClass: "row my-3 text-center" },
                      [
                        _c("div", { staticClass: "col" }, [
                          _vm._v("\n            Contest Start "),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-large" }, [
                            _vm._v(
                              _vm._s(_vm.formatDate(_vm.contestInfo.starttime))
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.contestInfo.currentRelTime >= 0 &&
                        _vm.contestInfo.currentRemTime >= 0
                          ? [
                              _c("div", { staticClass: "col" }, [
                                _vm._v("\n              Contest Time "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-large" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatTimeDiff(
                                        _vm.contestInfo.currentRelTime
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._v("\n              Remaining Time "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-large" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatTimeDiff(
                                        _vm.contestInfo.currentRemTime
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.contestInfo.currentRelTime < 0
                          ? [
                              _c("div", { staticClass: "col" }, [
                                _vm._v("\n              Contest Starts In "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-large" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatTimeDiff(
                                        -_vm.contestInfo.currentRelTime
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._v("\n              Contest Duration "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-large" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatTimeDiff(
                                        _vm.contestInfo.duration * 1000
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.contestInfo.currentRemTime < 0
                          ? [
                              _c("div", { staticClass: "col" }, [
                                _vm._v("\n              Contest Ended "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-large" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatTimeDiff(
                                        -_vm.contestInfo.currentRemTime
                                      )
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._v("\n              Contest Duration "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-large" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatTimeDiff(
                                        _vm.contestInfo.duration * 1000
                                      )
                                    )
                                  )
                                ])
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("table", { staticClass: "table mt-3" }, [
                  _c("thead", { staticClass: "thead-dark" }, [
                    _c(
                      "tr",
                      [
                        _c("th", [_vm._v("Rank")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Name")]),
                        _vm._v(" "),
                        _c("th", { staticClass: "text-right" }, [
                          _vm._v("Score")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.showRanking.tasks, function(task) {
                          return _c(
                            "th",
                            {
                              staticClass: "text-right",
                              class:
                                _vm.objSize(_vm.showRanking.tasks) > 5
                                  ? "rotated"
                                  : ""
                            },
                            [_c("div", [_vm._v(_vm._s(task.name))])]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.showRanking.ranking, function(rank) {
                      return _c(
                        "tr",
                        [
                          _c("td", [_vm._v(_vm._s(rank.rank))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(rank.name))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass: "text-right",
                              class: _vm.openContest !== "" ? "clickable" : "",
                              on: {
                                click: function($event) {
                                  return _vm.loadSubmissions(0, rank.id)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(rank.score) +
                                  "\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.showRanking.tasks, function(task, id) {
                            return _c(
                              "td",
                              {
                                staticClass: "clickable text-right",
                                on: {
                                  click: function($event) {
                                    return _vm.loadSubmissions(id, rank.id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(rank.scores[id]) +
                                    "\n              "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _vm.openSubmissions.participant
                  ? _c("div", [
                      _c("h2", [
                        _vm._v(
                          "\n            Submissions for\n            " +
                            _vm._s(
                              _vm.participants[_vm.openSubmissions.participant]
                                .name
                            ) +
                            ":\n            " +
                            _vm._s(
                              _vm.openSubmissions.task
                                ? _vm.showRanking.tasks[
                                    _vm.openSubmissions.task
                                  ].name
                                : "all submissions"
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("canvas", {
                        attrs: {
                          id: "plotcanvas",
                          width: "100px",
                          height: "200px"
                        }
                      }),
                      _vm._v(" "),
                      _c("table", { staticClass: "table mt-3" }, [
                        _c("thead", { staticClass: "thead-dark" }, [
                          _c("tr", [
                            _c("th", [_vm._v("Absolute Time")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Contest Time")]),
                            _vm._v(" "),
                            !_vm.openSubmissions.task
                              ? _c("th", [_vm._v("Task")])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("th", [_vm._v("Score")])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.sortedOpenSubmissions, function(sub) {
                            return _c("tr", [
                              _c("td", [
                                _vm._v(_vm._s(_vm.formatDate(sub.abs_time)))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatTimeDiff(sub.rel_time * 1000)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              !_vm.openSubmissions.task
                                ? _c("td", [
                                    _vm._v(_vm._s(_vm.tasks[sub.task_id].name))
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(sub.score))])
                            ])
                          }),
                          0
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-2 text-center" }, [
                        _c(
                          "button",
                          {
                            staticClass: "mb-2 btn btn-outline-dark",
                            on: { click: _vm.closeSubmissions }
                          },
                          [_vm._v("\n              Hide\n            ")]
                        )
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }