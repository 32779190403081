var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("my-header", {
        attrs: {
          title: "Scoreboard Betting",
          admin: _vm.admin,
          "logged-in": _vm.loggedIn,
          username: _vm.username
        },
        on: {
          logout: function($event) {
            return _vm.$emit("logout")
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container mt-2" }, [
        _c(
          "ul",
          { staticClass: "nav nav-tabs mb-2" },
          _vm._l(_vm.events, function(ev, id) {
            return _c("li", { staticClass: "nav-item" }, [
              _c(
                "div",
                {
                  staticClass: "nav-link clickable",
                  class: id === _vm.openEvent ? "active" : "",
                  on: {
                    click: function($event) {
                      return _vm.$emit("openEvent", id)
                    }
                  }
                },
                [_vm._v("\n          " + _vm._s(ev) + "\n        ")]
              )
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm.openEvent !== ""
          ? _c("div", [
              _c(
                "ul",
                { staticClass: "nav nav-pills mb-3" },
                [
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "nav-link clickable",
                        class: "" === _vm.openContest ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.$emit("openContest", "")
                          }
                        }
                      },
                      [_vm._v("\n            Overview\n          ")]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.contests, function(contest, id) {
                    return _c("li", { staticClass: "nav-item" }, [
                      _c(
                        "div",
                        {
                          staticClass: "nav-link clickable",
                          class: id === _vm.openContest ? "active" : "",
                          on: {
                            click: function($event) {
                              return _vm.$emit("openContest", id)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(contest) + "\n          "
                          )
                        ]
                      )
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.openContest === ""
                ? _c("div", [
                    _c("h2", [
                      _vm._v("Betting " + _vm._s(_vm.events[_vm.openEvent]))
                    ]),
                    _vm._v(" "),
                    _c("table", { staticClass: "table mt-3" }, [
                      _c("thead", { staticClass: "thead-dark" }, [
                        _c("tr", [
                          _c("th", [_vm._v("Rank")]),
                          _vm._v(" "),
                          _vm.eventInfo.status !== 0 && _vm.ranking_dict
                            ? _c("th", [_vm._v("Rank Real")])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("th", [_vm._v("Name")]),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              class:
                                _vm.eventInfo.status !== 0 ? "text-right" : ""
                            },
                            [_vm._v("\n                Score\n              ")]
                          ),
                          _vm._v(" "),
                          _vm.eventInfo.status !== 0 && _vm.ranking_dict
                            ? _c("th", { staticClass: "text-right" }, [
                                _vm._v(
                                  "\n                Score Real\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.eventInfo.status !== 0 && _vm.ranking_dict
                            ? _c("th", { staticClass: "text-right" }, [
                                _vm._v(
                                  "\n                Difference\n              "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.bets_event_sorted, function(part) {
                          return _vm.ranking_dict && _vm.ranking_dict[part.id]
                            ? _c("tr", { key: part.id }, [
                                _vm.eventInfo.status === 0
                                  ? _c("td", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.number",
                                            value:
                                              _vm.bets_event[part.id].place,
                                            expression:
                                              "bets_event[part.id].place",
                                            modifiers: { number: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          max: _vm.participants_count,
                                          id: "partRankingEvent" + part.id
                                        },
                                        domProps: {
                                          value: _vm.bets_event[part.id].place
                                        },
                                        on: {
                                          input: [
                                            function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.bets_event[part.id],
                                                "place",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            function($event) {
                                              _vm.submitBetEventParticipant(
                                                part.id
                                              )
                                              _vm.refocusInput(
                                                "partRankingEvent" + part.id
                                              )
                                            }
                                          ],
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      })
                                    ])
                                  : _c("td", [_vm._v(_vm._s(part.place))]),
                                _vm._v(" "),
                                _vm.eventInfo.status !== 0
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.ranking_dict[part.id].rank
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.participants[part.id].name))
                                ]),
                                _vm._v(" "),
                                _vm.eventInfo.status === 0
                                  ? _c("td", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.number",
                                            value:
                                              _vm.bets_event[part.id].score,
                                            expression:
                                              "bets_event[part.id].score",
                                            modifiers: { number: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "number",
                                          min: "0",
                                          id: "partScoreEvent" + part.id
                                        },
                                        domProps: {
                                          value: _vm.bets_event[part.id].score
                                        },
                                        on: {
                                          input: [
                                            function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.bets_event[part.id],
                                                "score",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            function($event) {
                                              _vm.submitBetEventParticipant(
                                                part.id
                                              )
                                              _vm.refocusInput(
                                                "partScoreEvent" + part.id
                                              )
                                            }
                                          ],
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      })
                                    ])
                                  : _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(part.score))
                                    ]),
                                _vm._v(" "),
                                _vm.eventInfo.status !== 0
                                  ? _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.ranking_dict[part.id].score
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.eventInfo.status !== 0
                                  ? _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            part.score -
                                              _vm.ranking_dict[part.id].score
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ]),
                    _vm._v(" "),
                    _vm.eventInfo.status === 0
                      ? _c("div", { staticClass: "mb-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              on: { click: _vm.setRankingFromScore }
                            },
                            [
                              _vm._v(
                                "\n            Calculate Ranks\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              on: { click: _vm.setOverviewFromBets }
                            },
                            [
                              _vm._v(
                                "\n            Calculate Scores\n          "
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.openContest !== ""
                ? _c("div", [
                    _c("h2", [
                      _vm._v(
                        "Betting " +
                          _vm._s(_vm.events[_vm.openEvent]) +
                          " " +
                          _vm._s(_vm.contests[_vm.openContest])
                      )
                    ]),
                    _vm._v(" "),
                    _vm.openContest !== "" && _vm.contestInfo.currentRelTime
                      ? _c(
                          "div",
                          { staticClass: "row my-3 text-center" },
                          [
                            _c("div", { staticClass: "col" }, [
                              _vm._v("\n            Contest Start "),
                              _c("br"),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-large" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDate(_vm.contestInfo.starttime)
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.contestInfo.currentRelTime >= 0 &&
                            _vm.contestInfo.currentRemTime >= 0
                              ? [
                                  _c("div", { staticClass: "col" }, [
                                    _vm._v("\n              Contest Time "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-large" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatTimeDiff(
                                            _vm.contestInfo.currentRelTime
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col" }, [
                                    _vm._v("\n              Remaining Time "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-large" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatTimeDiff(
                                            _vm.contestInfo.currentRemTime
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.contestInfo.currentRelTime < 0
                              ? [
                                  _c("div", { staticClass: "col" }, [
                                    _vm._v(
                                      "\n              Contest Starts In "
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-large" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatTimeDiff(
                                            -_vm.contestInfo.currentRelTime
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col" }, [
                                    _vm._v("\n              Contest Duration "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-large" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatTimeDiff(
                                            _vm.contestInfo.duration * 1000
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.contestInfo.currentRemTime < 0
                              ? [
                                  _c("div", { staticClass: "col" }, [
                                    _vm._v("\n              Contest Ended "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-large" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatTimeDiff(
                                            -_vm.contestInfo.currentRemTime
                                          )
                                        )
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col" }, [
                                    _vm._v("\n              Contest Duration "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-large" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatTimeDiff(
                                            _vm.contestInfo.duration * 1000
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("table", { staticClass: "table mt-3" }, [
                      _c("thead", { staticClass: "thead-dark" }, [
                        _c("tr", [
                          _c("th", [_vm._v("Rank")]),
                          _vm._v(" "),
                          _vm.contestInfo.status !== 0 && _vm.ranking_dict
                            ? _c("th", [
                                _vm._v(
                                  "\n                Rank Real\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("th", [_vm._v("Name")]),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              class:
                                _vm.contestInfo.status !== 0 ? "text-right" : ""
                            },
                            [_vm._v("\n                Score\n              ")]
                          ),
                          _vm._v(" "),
                          _vm.contestInfo.status !== 0 && _vm.ranking_dict
                            ? _c("th", { staticClass: "text-right" }, [
                                _vm._v(
                                  "\n                Score Real\n              "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.contestInfo.status !== 0 && _vm.ranking_dict
                            ? _c("th", { staticClass: "text-right" }, [
                                _vm._v(
                                  "\n                Difference\n              "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.bets_contest_sorted, function(part) {
                          return _vm.ranking_dict && _vm.ranking_dict[part.id]
                            ? _c("tr", { key: part.id }, [
                                _vm.contestInfo.status === 0
                                  ? _c("td", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.number",
                                            value:
                                              _vm.bets_contest[part.id].place,
                                            expression:
                                              "bets_contest[part.id].place",
                                            modifiers: { number: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          max: _vm.participants_count,
                                          id: "partRankingContest" + part.id
                                        },
                                        domProps: {
                                          value: _vm.bets_contest[part.id].place
                                        },
                                        on: {
                                          input: [
                                            function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.bets_contest[part.id],
                                                "place",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            function($event) {
                                              _vm.submitBetContestParticipant(
                                                part.id
                                              )
                                              _vm.refocusInput(
                                                "partRankingContest" + part.id
                                              )
                                            }
                                          ],
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      })
                                    ])
                                  : _c("td", [_vm._v(_vm._s(part.place))]),
                                _vm._v(" "),
                                _vm.contestInfo.status !== 0 && _vm.ranking_dict
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.ranking_dict[part.id].rank
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.participants[part.id].name))
                                ]),
                                _vm._v(" "),
                                _vm.contestInfo.status === 0
                                  ? _c("td", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.number",
                                            value:
                                              _vm.bets_contest[part.id].score,
                                            expression:
                                              "bets_contest[part.id].score",
                                            modifiers: { number: true }
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "number",
                                          min: "0",
                                          max: _vm.tasks_count * 100,
                                          id: "partScoreContest" + part.id
                                        },
                                        domProps: {
                                          value: _vm.bets_contest[part.id].score
                                        },
                                        on: {
                                          input: [
                                            function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.bets_contest[part.id],
                                                "score",
                                                _vm._n($event.target.value)
                                              )
                                            },
                                            function($event) {
                                              _vm.submitBetContestParticipant(
                                                part.id
                                              )
                                              _vm.refocusInput(
                                                "partScoreContest" + part.id
                                              )
                                            }
                                          ],
                                          blur: function($event) {
                                            return _vm.$forceUpdate()
                                          }
                                        }
                                      })
                                    ])
                                  : _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(part.score))
                                    ]),
                                _vm._v(" "),
                                _vm.contestInfo.status !== 0 && _vm.ranking_dict
                                  ? _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.ranking_dict[part.id].score
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.contestInfo.status !== 0 && _vm.ranking_dict
                                  ? _c("td", { staticClass: "text-right" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            part.score -
                                              _vm.ranking_dict[part.id].score
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ]),
                    _vm._v(" "),
                    _vm.contestInfo.status === 0
                      ? _c("div", { staticClass: "mb-4" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              on: { click: _vm.setRankingFromScore }
                            },
                            [
                              _vm._v(
                                "\n            Calculate Ranks\n          "
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("table", { staticClass: "table" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _vm.contestInfo.status === 0
                        ? _c(
                            "tbody",
                            _vm._l(_vm.tasks, function(task, id) {
                              return _c("tr", { key: task.name }, [
                                _c("td", [_vm._v(_vm._s(task.name))]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("div", { staticClass: "form-inline" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value:
                                            _vm.bets_task[id]
                                              .first_full_score_hour,
                                          expression:
                                            "bets_task[id].first_full_score_hour",
                                          modifiers: { number: true }
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        min: "0",
                                        max: "4"
                                      },
                                      domProps: {
                                        value:
                                          _vm.bets_task[id]
                                            .first_full_score_hour
                                      },
                                      on: {
                                        input: [
                                          function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.bets_task[id],
                                              "first_full_score_hour",
                                              _vm._n($event.target.value)
                                            )
                                          },
                                          function($event) {
                                            return _vm.submitBetTask(id)
                                          }
                                        ],
                                        blur: function($event) {
                                          return _vm.$forceUpdate()
                                        }
                                      }
                                    }),
                                    _vm._v(":"),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value:
                                            _vm.bets_task[id]
                                              .first_full_score_min,
                                          expression:
                                            "bets_task[id].first_full_score_min",
                                          modifiers: { number: true }
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        min: "0",
                                        max: "59"
                                      },
                                      domProps: {
                                        value:
                                          _vm.bets_task[id].first_full_score_min
                                      },
                                      on: {
                                        input: [
                                          function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.bets_task[id],
                                              "first_full_score_min",
                                              _vm._n($event.target.value)
                                            )
                                          },
                                          function($event) {
                                            return _vm.submitBetTask(id)
                                          }
                                        ],
                                        blur: function($event) {
                                          return _vm.$forceUpdate()
                                        }
                                      }
                                    })
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: _vm.bets_task[id].num_full_score,
                                        expression:
                                          "bets_task[id].num_full_score",
                                        modifiers: { number: true }
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      min: "0",
                                      max: _vm.participants_count
                                    },
                                    domProps: {
                                      value: _vm.bets_task[id].num_full_score
                                    },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.bets_task[id],
                                            "num_full_score",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        function($event) {
                                          return _vm.submitBetTask(id)
                                        }
                                      ],
                                      blur: function($event) {
                                        return _vm.$forceUpdate()
                                      }
                                    }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value: _vm.bets_task[id].avg_score,
                                        expression: "bets_task[id].avg_score",
                                        modifiers: { number: true }
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      min: "0",
                                      max: "100"
                                    },
                                    domProps: {
                                      value: _vm.bets_task[id].avg_score
                                    },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.bets_task[id],
                                            "avg_score",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        function($event) {
                                          return _vm.submitBetTask(id)
                                        }
                                      ],
                                      blur: function($event) {
                                        return _vm.$forceUpdate()
                                      }
                                    }
                                  })
                                ])
                              ])
                            }),
                            0
                          )
                        : _c(
                            "tbody",
                            _vm._l(_vm.tasks, function(task, id) {
                              return _vm.taskresults[id]
                                ? _c("tr", { key: task.name }, [
                                    _c("td", [_vm._v(_vm._s(task.name))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.zeroPad(
                                              _vm.bets_task[id]
                                                .first_full_score_hour,
                                              2
                                            )
                                          ) +
                                          ":" +
                                          _vm._s(
                                            _vm.zeroPad(
                                              _vm.bets_task[id]
                                                .first_full_score_min,
                                              2
                                            )
                                          ) +
                                          "\n                (real:\n                " +
                                          _vm._s(
                                            _vm.zeroPad(
                                              _vm.taskresults[id]
                                                .first_full_score_hour,
                                              2
                                            )
                                          ) +
                                          ":" +
                                          _vm._s(
                                            _vm.zeroPad(
                                              _vm.taskresults[id]
                                                .first_full_score_min,
                                              2
                                            )
                                          ) +
                                          ", diff: " +
                                          _vm._s(
                                            _vm.taskresults[id]
                                              .first_full_score_diff
                                          ) +
                                          "')\n              "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.bets_task[id].num_full_score
                                          ) +
                                          " (real:\n                " +
                                          _vm._s(
                                            _vm.taskresults[id].num_full_score
                                          ) +
                                          ", diff:\n                " +
                                          _vm._s(
                                            _vm.bets_task[id].num_full_score -
                                              _vm.taskresults[id].num_full_score
                                          ) +
                                          ")\n              "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.bets_task[id].avg_score) +
                                          " (real:\n                " +
                                          _vm._s(
                                            _vm.taskresults[id].avg_score
                                          ) +
                                          ", diff:\n                " +
                                          _vm._s(
                                            _vm.bets_task[id].avg_score -
                                              _vm.taskresults[id].avg_score
                                          ) +
                                          ")\n              "
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            }),
                            0
                          )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.bets_scores
                ? _c("div", [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", { staticClass: "thead-dark" }, [
                        _c("tr", [
                          _c("th", [_vm._v("Rank")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Username")]),
                          _vm._v(" "),
                          _vm.openContest === ""
                            ? _c(
                                "th",
                                {
                                  staticClass: "clickable",
                                  on: {
                                    click: function($event) {
                                      return _vm.setBetsRankingSorting(3)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Score Event\n              "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass: "clickable",
                              on: {
                                click: function($event) {
                                  return _vm.setBetsRankingSorting(2)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                Score Ranking\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass: "clickable",
                              on: {
                                click: function($event) {
                                  return _vm.setBetsRankingSorting(1)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                Score Task\n              "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass: "clickable",
                              on: {
                                click: function($event) {
                                  return _vm.setBetsRankingSorting(0)
                                }
                              }
                            },
                            [_vm._v("\n                Score\n              ")]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.bets_ranking, function(user, id) {
                          return _c("tr", { key: user.name }, [
                            _c("td", [_vm._v(_vm._s(user.rank))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(user.name))]),
                            _vm._v(" "),
                            _vm.openContest === ""
                              ? _c("td", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        Math.round(user.score_event * 100) / 100
                                      ) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  Math.round(user.score_ranking * 100) / 100
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(Math.round(user.score_task * 100) / 100)
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(Math.round(user.score * 100) / 100))
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-dark" }, [
      _c("tr", [
        _c("th", [_vm._v("Task")]),
        _vm._v(" "),
        _c("th", [_vm._v("First Full Score")]),
        _vm._v(" "),
        _c("th", [_vm._v("Number of Full Scores")]),
        _vm._v(" "),
        _c("th", [_vm._v("Average Score")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }