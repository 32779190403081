<template>
  <router-view
    :userid="userid"
    :loggedIn="loggedIn"
    :username="username"
    :admin="admin"
    :events="events"
    :contests="contests"
    :tasks="tasks"
    :participants="participants"
    :openEvent="openEvent"
    :openContest="openContest"
    :eventInfo="eventInfo"
    :eventScores="eventScores"
    :contestInfo="contestInfo"
    :contestScores="contestScores"
    @openEvent="setOpenEvent"
    @openContest="setOpenContest"
    @loadEvents="loadEvents"
    @loadContests="loadContests"
    @loadTasks="loadTasks"
    @loadParticipants="loadParticipants"
    @logout="logout"
  />
</template>

<script>
import { fetchAPI, postAPI } from "../fetch-utils";
import moment from "moment";

export default {
  data() {
    return {
      userid: 0,
      loggedIn: false,
      username: "",
      admin: false,
      events: {},
      contests: {},
      tasks: {},
      participants: {},
      openEvent: "",
      openContest: "",
      eventInfo: {
        public: false,
        status: 0,
      },
      eventScores: {},
      contestInfo: {
        public: false,
        status: 0,
        starttime: 0,
        currentRelTime: 0,
        currentRemTime: 0,
        duration: 0,
        syncKey: 0,
        freezeTime: 0,
        freezeTimePublic: 0,
        timeUpdateId: 0,
      },
      contestScores: {},
    };
  },
  created() {
    this.loadWhoAmI();
    this.loadEvents();
    this.checkOpenEventAndContest();
  },
  mounted() {
    setInterval(this.checkVersion, 1000);
    setInterval(this.calcContestCurrentRelTime, 250);
  },
  methods: {
    loadWhoAmI() {
      fetchAPI("/api/me")
        .then((res) => {
          this.userid = res.id;
          this.username = res.username;
          this.admin = res.admin;
          this.loggedIn = true;
        })
        .catch((err) => {
          this.userid = 0;
          this.username = "";
          this.admin = false;
          this.loggedIn = false;
        });
    },
    logout() {
      postAPI("/api/logout").then((res) => {
        this.userid = 0;
        this.username = "";
        this.admin = false;
        this.loggedIn = false;
        localStorage.clear();
      });
    },
    loadEvents() {
      fetchAPI("/api/list/").then((res) => {
        this.events = res;
      });
    },
    loadContests() {
      if (this.openEvent === "" || isNaN(this.openEvent)) {
        return;
      }
      fetchAPI("/api/event/" + this.openEvent + "/list/").then((res) => {
        this.contests = res;
      });
    },
    loadParticipants() {
      if (this.openEvent === "" || isNaN(this.openEvent)) {
        return;
      }
      fetchAPI("/api/event/" + this.openEvent + "/participants/").then(
        (res) => {
          this.participants = res;
        }
      );
    },
    loadTasks() {
      if (this.openContest === "" || isNaN(this.openContest)) {
        return;
      }
      fetchAPI("/api/contest/" + this.openContest + "/tasks/").then((res) => {
        this.tasks = res;
      });
    },
    loadEventStatus() {
      if (this.openEvent === "" || isNaN(this.openEvent)) {
        return;
      }
      fetchAPI("/api/event/" + this.openEvent + "/status/").then((res) => {
        this.eventInfo.public = res.public;
        this.eventInfo.status = res.status;
      });
    },
    loadEventScores() {
      if (!this.openEvent) return;
      fetchAPI("/api/event/" + this.openEvent + "/scores/").then((res) => {
        this.eventScores = res;
      });
    },
    loadContestStatus() {
      if (this.openContest === "" || isNaN(this.openContest)) {
        return;
      }
      fetchAPI("/api/contest/" + this.openContest + "/status/").then((res) => {
        this.contestInfo.public = res.public;
        this.contestInfo.status = res.status;
        this.contestInfo.starttime = res.starttime;
        this.contestInfo.duration = res.duration;
        this.contestInfo.syncKey = res.synckey;
        this.contestInfo.freezeTime = Math.floor(res.freeze_time / 60);
        this.contestInfo.freezeTimePublic = Math.floor(
          res.freeze_time_public / 60
        );
      });
    },
    loadContestScores() {
      fetchAPI("/api/contest/" + this.openContest + "/scores/").then((res) => {
        this.contestScores = res;
      });
    },
    setOpenEvent(event) {
      if (isNaN(event)) {
        event = "";
      }
      var changed = event !== this.openEvent;
      this.openEvent = event;
      localStorage.setItem("openEvent", event);
      if (event !== "") {
        this.loadEventStatus();
        this.loadContests();
        this.loadParticipants();
        this.loadEventScores();
      }
      if (changed || event === "") {
        this.setOpenContest("");
      }
    },
    setOpenContest(contest) {
      if (isNaN(contest)) {
        contest = "";
      }
      this.openContest = contest;
      localStorage.setItem("openContest", contest);
      if (contest !== "") {
        this.loadContestStatus();
        this.loadTasks();
        this.loadContestScores();
      } else {
        this.loadEventScores();
      }
    },
    checkOpenEventAndContest() {
      if (localStorage.getItem("openEvent")) {
        var oldOpenContest = localStorage.getItem("openContest");
        this.setOpenEvent(localStorage.getItem("openEvent"));
        if (oldOpenContest !== "") {
          this.setOpenContest(oldOpenContest);
        }
      }
    },
    calcContestCurrentRelTime() {
      const start = moment.unix(this.contestInfo.starttime);
      const diff = moment().diff(start);
      this.contestInfo.currentRelTime = diff;
      this.contestInfo.currentRemTime = this.contestInfo.duration * 1000 - diff;
    },
    checkVersion() {
      if (this.openContest === "") {
        if (this.openEvent !== "") {
          fetchAPI("/api/event/" + this.openEvent + "/rankingversion/").then(
            (res) => {
              if (res !== this.eventScores.version) {
                this.loadEventScores();
              }
            }
          );
        }
      } else {
        fetchAPI("/api/contest/" + this.openContest + "/rankingversion/").then(
          (res) => {
            if (res !== this.contestScores.version) {
              this.loadContestScores();
              this.loadContestStatus();
            }
          }
        );
      }
    },
  },
};
</script>
