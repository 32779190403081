<template>
  <div>
    <my-header
      title="Scoreboard Betting"
      :admin="admin"
      :logged-in="loggedIn"
      :username="username"
      @logout="$emit('logout')"
    />

    <div class="container mt-2">
      <ul class="nav nav-tabs mb-2">
        <li class="nav-item" v-for="(ev, id) in events">
          <div
            class="nav-link clickable"
            :class="id === openEvent ? 'active' : ''"
            @click="$emit('openEvent', id)"
          >
            {{ ev }}
          </div>
        </li>
      </ul>
      <div v-if="openEvent !== ''">
        <ul class="nav nav-pills mb-3">
          <li class="nav-item">
            <div
              class="nav-link clickable"
              :class="'' === openContest ? 'active' : ''"
              @click="$emit('openContest', '')"
            >
              Overview
            </div>
          </li>
          <li class="nav-item" v-for="(contest, id) in contests">
            <div
              class="nav-link clickable"
              :class="id === openContest ? 'active' : ''"
              @click="$emit('openContest', id)"
            >
              {{ contest }}
            </div>
          </li>
        </ul>
        <div v-if="openContest === ''">
          <h2>Betting {{ events[openEvent] }}</h2>
          <table class="table mt-3">
            <thead class="thead-dark">
              <tr>
                <th>Rank</th>
                <th v-if="eventInfo.status !== 0 && ranking_dict">Rank Real</th>
                <th>Name</th>
                <th :class="eventInfo.status !== 0 ? 'text-right' : ''">
                  Score
                </th>
                <th
                  class="text-right"
                  v-if="eventInfo.status !== 0 && ranking_dict"
                >
                  Score Real
                </th>
                <th
                  class="text-right"
                  v-if="eventInfo.status !== 0 && ranking_dict"
                >
                  Difference
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="part in bets_event_sorted"
                v-if="ranking_dict && ranking_dict[part.id]"
                :key="part.id"
              >
                <td v-if="eventInfo.status === 0">
                  <input
                    class="form-control"
                    type="number"
                    min="1"
                    :max="participants_count"
                    v-model.number="bets_event[part.id].place"
                    @input="
                      submitBetEventParticipant(part.id);
                      refocusInput('partRankingEvent' + part.id);
                    "
                    :id="'partRankingEvent' + part.id"
                  />
                </td>
                <td v-else>{{ part.place }}</td>
                <td v-if="eventInfo.status !== 0">
                  {{ ranking_dict[part.id].rank }}
                </td>
                <td>{{ participants[part.id].name }}</td>
                <td v-if="eventInfo.status === 0">
                  <input
                    class="form-control"
                    type="number"
                    min="0"
                    v-model.number="bets_event[part.id].score"
                    @input="
                      submitBetEventParticipant(part.id);
                      refocusInput('partScoreEvent' + part.id);
                    "
                    :id="'partScoreEvent' + part.id"
                  />
                </td>
                <td class="text-right" v-else>{{ part.score }}</td>
                <td class="text-right" v-if="eventInfo.status !== 0">
                  {{ ranking_dict[part.id].score }}
                </td>
                <td class="text-right" v-if="eventInfo.status !== 0">
                  {{ part.score - ranking_dict[part.id].score }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mb-4" v-if="eventInfo.status === 0">
            <button class="btn btn-secondary" @click="setRankingFromScore">
              Calculate Ranks
            </button>
            <button class="btn btn-secondary" @click="setOverviewFromBets">
              Calculate Scores
            </button>
          </div>
        </div>
        <div v-if="openContest !== ''">
          <h2>Betting {{ events[openEvent] }} {{ contests[openContest] }}</h2>
          <div
            v-if="openContest !== '' && contestInfo.currentRelTime"
            class="row my-3 text-center"
          >
            <div class="col">
              Contest Start <br />
              <span class="text-large">{{
                formatDate(contestInfo.starttime)
              }}</span>
            </div>
            <template
              v-if="
                contestInfo.currentRelTime >= 0 &&
                contestInfo.currentRemTime >= 0
              "
            >
              <div class="col">
                Contest Time <br />
                <span class="text-large">{{
                  formatTimeDiff(contestInfo.currentRelTime)
                }}</span>
              </div>
              <div class="col">
                Remaining Time <br />
                <span class="text-large">{{
                  formatTimeDiff(contestInfo.currentRemTime)
                }}</span>
              </div>
            </template>
            <template v-if="contestInfo.currentRelTime < 0">
              <div class="col">
                Contest Starts In <br />
                <span class="text-large">{{
                  formatTimeDiff(-contestInfo.currentRelTime)
                }}</span>
              </div>
              <div class="col">
                Contest Duration <br />
                <span class="text-large">{{
                  formatTimeDiff(contestInfo.duration * 1000)
                }}</span>
              </div>
            </template>
            <template v-if="contestInfo.currentRemTime < 0">
              <div class="col">
                Contest Ended <br />
                <span class="text-large">{{
                  formatTimeDiff(-contestInfo.currentRemTime)
                }}</span>
              </div>
              <div class="col">
                Contest Duration <br />
                <span class="text-large">{{
                  formatTimeDiff(contestInfo.duration * 1000)
                }}</span>
              </div>
            </template>
          </div>
          <table class="table mt-3">
            <thead class="thead-dark">
              <tr>
                <th>Rank</th>
                <th v-if="contestInfo.status !== 0 && ranking_dict">
                  Rank Real
                </th>
                <th>Name</th>
                <th :class="contestInfo.status !== 0 ? 'text-right' : ''">
                  Score
                </th>
                <th
                  class="text-right"
                  v-if="contestInfo.status !== 0 && ranking_dict"
                >
                  Score Real
                </th>
                <th
                  class="text-right"
                  v-if="contestInfo.status !== 0 && ranking_dict"
                >
                  Difference
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="part in bets_contest_sorted"
                v-if="ranking_dict && ranking_dict[part.id]"
                :key="part.id"
              >
                <td v-if="contestInfo.status === 0">
                  <input
                    class="form-control"
                    type="number"
                    min="1"
                    :max="participants_count"
                    v-model.number="bets_contest[part.id].place"
                    @input="
                      submitBetContestParticipant(part.id);
                      refocusInput('partRankingContest' + part.id);
                    "
                    :id="'partRankingContest' + part.id"
                  />
                </td>
                <td v-else>{{ part.place }}</td>
                <td v-if="contestInfo.status !== 0 && ranking_dict">
                  {{ ranking_dict[part.id].rank }}
                </td>
                <td>{{ participants[part.id].name }}</td>
                <td v-if="contestInfo.status === 0">
                  <input
                    class="form-control"
                    type="number"
                    min="0"
                    :max="tasks_count * 100"
                    v-model.number="bets_contest[part.id].score"
                    @input="
                      submitBetContestParticipant(part.id);
                      refocusInput('partScoreContest' + part.id);
                    "
                    :id="'partScoreContest' + part.id"
                  />
                </td>
                <td class="text-right" v-else>{{ part.score }}</td>
                <td
                  class="text-right"
                  v-if="contestInfo.status !== 0 && ranking_dict"
                >
                  {{ ranking_dict[part.id].score }}
                </td>
                <td
                  class="text-right"
                  v-if="contestInfo.status !== 0 && ranking_dict"
                >
                  {{ part.score - ranking_dict[part.id].score }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mb-4" v-if="contestInfo.status === 0">
            <button class="btn btn-secondary" @click="setRankingFromScore">
              Calculate Ranks
            </button>
          </div>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th>Task</th>
                <th>First Full Score</th>
                <th>Number of Full Scores</th>
                <th>Average Score</th>
              </tr>
            </thead>
            <tbody v-if="contestInfo.status === 0">
              <tr v-for="(task, id) in tasks" :key="task.name">
                <td>{{ task.name }}</td>
                <td>
                  <div class="form-inline">
                    <input
                      class="form-control"
                      type="number"
                      min="0"
                      max="4"
                      v-model.number="bets_task[id].first_full_score_hour"
                      @input="submitBetTask(id)"
                    />:<input
                      class="form-control"
                      type="number"
                      min="0"
                      max="59"
                      v-model.number="bets_task[id].first_full_score_min"
                      @input="submitBetTask(id)"
                    />
                  </div>
                </td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    min="0"
                    :max="participants_count"
                    v-model.number="bets_task[id].num_full_score"
                    @input="submitBetTask(id)"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    min="0"
                    max="100"
                    v-model.number="bets_task[id].avg_score"
                    @input="submitBetTask(id)"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="(task, id) in tasks"
                v-if="taskresults[id]"
                :key="task.name"
              >
                <td>{{ task.name }}</td>
                <td>
                  {{ zeroPad(bets_task[id].first_full_score_hour, 2) }}:{{
                    zeroPad(bets_task[id].first_full_score_min, 2)
                  }}
                  (real:
                  {{ zeroPad(taskresults[id].first_full_score_hour, 2) }}:{{
                    zeroPad(taskresults[id].first_full_score_min, 2)
                  }}, diff: {{ taskresults[id].first_full_score_diff }}')
                </td>
                <td>
                  {{ bets_task[id].num_full_score }} (real:
                  {{ taskresults[id].num_full_score }}, diff:
                  {{
                    bets_task[id].num_full_score -
                    taskresults[id].num_full_score
                  }})
                </td>
                <td>
                  {{ bets_task[id].avg_score }} (real:
                  {{ taskresults[id].avg_score }}, diff:
                  {{ bets_task[id].avg_score - taskresults[id].avg_score }})
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="bets_scores">
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th>Rank</th>
                <th>Username</th>
                <th
                  v-if="openContest === ''"
                  class="clickable"
                  @click="setBetsRankingSorting(3)"
                >
                  Score Event
                </th>
                <th class="clickable" @click="setBetsRankingSorting(2)">
                  Score Ranking
                </th>
                <th class="clickable" @click="setBetsRankingSorting(1)">
                  Score Task
                </th>
                <th class="clickable" @click="setBetsRankingSorting(0)">
                  Score
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, id) in bets_ranking" :key="user.name">
                <td>{{ user.rank }}</td>
                <td>{{ user.name }}</td>
                <td v-if="openContest === ''">
                  {{ Math.round(user.score_event * 100) / 100 }}
                </td>
                <td>{{ Math.round(user.score_ranking * 100) / 100 }}</td>
                <td>{{ Math.round(user.score_task * 100) / 100 }}</td>
                <td>{{ Math.round(user.score * 100) / 100 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyHeader from "../components/my-header.vue";
import {
  common_props,
  formatDate,
  formatTimeDiff,
  objSize,
  zeroPad,
} from "../common";
import { fetchAPI, postAPI } from "../fetch-utils";
import { calc_bets_ranking, calc_ranking } from "../ranking";

function sort_betting(obj) {
  let res = [];
  for (const id in obj) {
    res.push({ ...obj[id], id: id });
  }
  res.sort((a, b) => {
    const placeDiff = a.place - b.place;
    return placeDiff === 0 ? b.score - a.score : placeDiff;
  });
  return res;
}

export default {
  components: { MyHeader },
  props: common_props,
  methods: {
    formatDate,
    formatTimeDiff,
    zeroPad,
    objSize,
    loadFirstFullScore() {
      if (this.openContest !== "") {
        fetchAPI("/api/contest/" + this.openContest + "/firstfullscore/")
          .then((res) => {
            this.first_full_scores = res;
          })
          .catch((res) => {
            this.first_full_scores = {};
          });
      } else {
        this.first_full_scores = {};
      }
    },
    loadBetEventParticipant(id) {
      fetchAPI("/api/event/" + this.openEvent + "/getbet/" + id + "/")
        .then((res) => {
          this.bets_event[id] = res;
        })
        .catch((res) => {
          this.bets_event[id] = {
            place: 1,
            score: 0,
          };
        });
    },
    submitBetEventParticipant(id) {
      postAPI(
        "/api/event/" + this.openEvent + "/placebet/" + id + "/",
        this.bets_event[id]
      );
    },
    loadBetContestParticipant(id) {
      if (this.openContest !== "") {
        fetchAPI("/api/contest/" + this.openContest + "/getbet/" + id + "/")
          .then((res) => {
            this.bets_contest[id] = res;
          })
          .catch((res) => {
            this.bets_contest[id] = {
              place: 1,
              score: 0,
            };
          });
      } else {
        this.bets_contest[id] = {
          place: 1,
          score: 0,
        };
      }
    },
    submitBetContestParticipant(id) {
      postAPI(
        "/api/contest/" + this.openContest + "/placebet/" + id + "/",
        this.bets_contest[id]
      );
    },
    loadBetTask(id) {
      fetchAPI("/api/task/" + id + "/getbet/")
        .then((res) => {
          var firstfullscore = Math.floor(res.first_full_score / 60);
          this.bets_task[id].first_full_score_min = firstfullscore % 60;
          this.bets_task[id].first_full_score_hour = Math.floor(
            firstfullscore / 60
          );
          this.bets_task[id].num_full_score = res.num_full_score;
          this.bets_task[id].avg_score = res.avg_score;
        })
        .catch((res) => {
          this.bets_task[id].first_full_score_min = 0;
          this.bets_task[id].first_full_score_hour = 0;
          this.bets_task[id].num_full_score = 0;
          this.bets_task[id].avg_score = 0;
        });
    },
    submitBetTask(id) {
      let data = { ...this.bets_task[id] };
      data.first_full_score =
        60 *
        (60 * parseInt(data.first_full_score_hour) +
          parseInt(data.first_full_score_min));
      delete data.first_full_score_hour;
      delete data.first_full_score_min;
      postAPI("/api/task/" + id + "/placebet/", data);
    },
    loadBetsScores() {
      const url =
        this.openContest !== ""
          ? "/api/contest/" + this.openContest + "/betscores/"
          : "/api/event/" + this.openEvent + "/betscores/";
      fetchAPI(url)
        .then((res) => {
          this.bets_scores = res;
        })
        .catch((res) => {
          this.bets_scores = {};
        });
    },
    setBetsRankingSorting(sorting) {
      this.bets_scores_sorted = sorting;
    },
    setRankingFromScore() {
      let dest;
      if (this.openContest === "") {
        dest = this.bets_event;
      } else {
        dest = this.bets_contest;
      }
      let sorted = [];
      for (const id in dest) {
        sorted.push({ ...dest[id], id: id });
      }
      sorted.sort((a, b) => b.score - a.score);
      let lastRank = 0;
      let lastScore = -1;
      for (const part of sorted) {
        if (part.score !== lastScore) {
          lastRank++;
        }
        if (dest[part.id].place !== lastRank) {
          dest[part.id].place = lastRank;
          if (this.openContest === "") {
            this.submitBetEventParticipant(part.id);
          } else {
            this.submitBetContestParticipant(part.id);
          }
        }
        lastScore = part.score;
      }
    },
    setOverviewFromBets() {
      const mythis = this;
      for (const part in this.participants) {
        this.bets_event[part].score = 0;
      }
      for (const id in this.contests) {
        var f = function () {
          var myid = id;
          fetchAPI("/api/contest/" + myid + "/status/").then((res) => {
            if (res.status === 0) {
              for (const part in mythis.participants) {
                var g = function () {
                  var mypart = part;
                  fetchAPI("/api/contest/" + myid + "/getbet/" + mypart + "/")
                    .then((res) => {
                      mythis.bets_event[mypart].score += res.score;
                      mythis.submitBetEventParticipant(mypart);
                    })
                    .catch((res) => undefined);
                };
                g();
              }
            } else {
              fetchAPI("/api/contest/" + myid + "/scores/").then((res) => {
                var scores = res.scores;
                for (const part in scores) {
                  for (const task in scores[part]) {
                    mythis.bets_event[part].score += scores[part][task];
                  }
                  mythis.submitBetEventParticipant(part);
                }
              });
            }
          });
        };
        f();
      }
    },
    refocusInput(id) {
      setTimeout(function () {
        document.getElementById(id).focus();
      }, 50);
    },
    loadAllBetTasks() {
      for (const id in this.tasks) {
        this.$set(this.bets_task, id, {
          first_full_score_hour: 0,
          first_full_score_min: 0,
          num_full_score: 0,
          avg_score: 0,
        });
        this.loadBetTask(id);
      }
    },
    loadParticipants() {
      for (const id in this.participants) {
        this.$set(this.bets_event, id, {
          place: 1,
          score: 0,
        });
        this.$set(this.bets_contest, id, {
          place: 1,
          score: 0,
        });
        this.loadBetEventParticipant(id);
        if (this.openContest) {
          this.loadBetContestParticipant(id);
        }
      }
    },
  },
  watch: {
    participants(new_val, old_val) {
      this.loadParticipants();
    },
    openContest(new_val, old_val) {
      for (const id in this.participants) {
        this.loadBetContestParticipant(id);
      }
      this.loadFirstFullScore();
    },
    tasks(new_val, old_val) {
      this.loadAllBetTasks();
    },
    contestScores(new_val, old_val) {
      this.loadFirstFullScore();
      this.loadBetsScores();
    },
    eventScores(new_val, old_val) {
      this.loadBetsScores();
    },
  },
  data() {
    return {
      bets_event: {},
      bets_contest: {},
      bets_task: {},
      first_full_scores: {},
      bets_scores: {},
      bets_scores_sorted: 0,
    };
  },
  beforeMount() {
    this.loadAllBetTasks();
    this.loadParticipants();
    this.loadBetsScores();
    this.loadFirstFullScore();
  },
  computed: {
    participants_count() {
      return this.objSize(this.participants);
    },
    tasks_count() {
      return this.objSize(this.tasks);
    },
    bets_event_sorted() {
      return sort_betting(this.bets_event);
    },
    bets_contest_sorted() {
      return sort_betting(this.bets_contest);
    },
    bets_ranking() {
      return calc_bets_ranking(this.bets_scores_sorted, this.bets_scores);
    },
    ranking_dict() {
      let res = {};
      if (this.showRanking.ranking) {
        for (const part of this.showRanking.ranking) {
          res[part.id] = part;
        }
        return res;
      }
      return null;
    },
    taskresults() {
      let res = {};
      if (!this.contestScores.scores || !this.tasks || !this.participants) {
        return res;
      }
      for (const task in this.tasks) {
        res[task] = {};
        var first_full = this.first_full_scores[task];
        if (first_full) {
          res[task].first_full_score_hour = Math.floor(first_full / 3600);
          res[task].first_full_score_min = Math.floor(first_full / 60) % 60;
          res[task].first_full_score_diff =
            (this.bets_task[task].first_full_score_hour -
              res[task].first_full_score_hour) *
              60 +
            (this.bets_task[task].first_full_score_min -
              res[task].first_full_score_min);
        } else {
          res[task].first_full_score_hour = "--";
          res[task].first_full_score_min = "--";
          res[task].first_full_score_diff = "∞";
        }
        res[task].num_full_score = 0;
        var sum = 0;
        for (const part in this.participants) {
          var score = (this.contestScores.scores[part] || {})[task] || 0;
          sum += score;
          if (score == 100) {
            res[task].num_full_score++;
          }
        }
        res[task].avg_score = Math.round(sum / this.objSize(this.participants));
      }
      return res;
    },
    eventRanking() {
      return calc_ranking(
        this.eventScores.scores,
        this.participants,
        this.eventScores.tasks
      );
    },
    contestRanking() {
      return calc_ranking(
        this.contestScores.scores,
        this.participants,
        this.tasks
      );
    },
    showRanking() {
      return this.openContest === "" ? this.eventRanking : this.contestRanking;
    },
  },
};
</script>
