<template>
  <div>
    <my-header
      title="Scoreboard"
      :admin="admin"
      :logged-in="loggedIn"
      :username="username"
      @logout="$emit('logout')"
    />

    <div class="container mt-2">
      <ul class="nav nav-tabs mb-2">
        <li class="nav-item" v-for="(ev, id) in events">
          <div
            class="nav-link clickable"
            :class="id === openEvent ? 'active' : ''"
            @click="$emit('openEvent', id)"
          >
            {{ ev }}
          </div>
        </li>
      </ul>
      <div v-if="openEvent !== ''">
        <ul class="nav nav-pills mb-3">
          <li class="nav-item">
            <div
              class="nav-link clickable"
              :class="'' === openContest ? 'active' : ''"
              @click="$emit('openContest', '')"
            >
              Overview
            </div>
          </li>
          <li class="nav-item" v-for="(contest, id) in contests">
            <div
              class="nav-link clickable"
              :class="id === openContest ? 'active' : ''"
              @click="$emit('openContest', id)"
            >
              {{ contest }}
            </div>
          </li>
        </ul>
        <div>
          <h2>
            Ranking {{ events[openEvent] }}
            {{ openContest !== "" ? contests[openContest] : "" }}
          </h2>
          <div
            v-if="openContest !== '' && contestInfo.currentRelTime"
            class="row my-3 text-center"
          >
            <div class="col">
              Contest Start <br />
              <span class="text-large">{{
                formatDate(contestInfo.starttime)
              }}</span>
            </div>
            <template
              v-if="
                contestInfo.currentRelTime >= 0 &&
                contestInfo.currentRemTime >= 0
              "
            >
              <div class="col">
                Contest Time <br />
                <span class="text-large">{{
                  formatTimeDiff(contestInfo.currentRelTime)
                }}</span>
              </div>
              <div class="col">
                Remaining Time <br />
                <span class="text-large">{{
                  formatTimeDiff(contestInfo.currentRemTime)
                }}</span>
              </div>
            </template>
            <template v-if="contestInfo.currentRelTime < 0">
              <div class="col">
                Contest Starts In <br />
                <span class="text-large">{{
                  formatTimeDiff(-contestInfo.currentRelTime)
                }}</span>
              </div>
              <div class="col">
                Contest Duration <br />
                <span class="text-large">{{
                  formatTimeDiff(contestInfo.duration * 1000)
                }}</span>
              </div>
            </template>
            <template v-if="contestInfo.currentRemTime < 0">
              <div class="col">
                Contest Ended <br />
                <span class="text-large">{{
                  formatTimeDiff(-contestInfo.currentRemTime)
                }}</span>
              </div>
              <div class="col">
                Contest Duration <br />
                <span class="text-large">{{
                  formatTimeDiff(contestInfo.duration * 1000)
                }}</span>
              </div>
            </template>
          </div>
          <table class="table mt-3">
            <thead class="thead-dark">
              <tr>
                <th>Rank</th>
                <th>Name</th>
                <th class="text-right">Score</th>
                <th
                  class="text-right"
                  :class="objSize(showRanking.tasks) > 5 ? 'rotated' : ''"
                  v-for="task in showRanking.tasks"
                >
                  <div>{{ task.name }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="rank in showRanking.ranking">
                <td>{{ rank.rank }}</td>
                <td>{{ rank.name }}</td>
                <td
                  class="text-right"
                  :class="openContest !== '' ? 'clickable' : ''"
                  @click="loadSubmissions(0, rank.id)"
                >
                  {{ rank.score }}
                </td>
                <td
                  v-for="(task, id) in showRanking.tasks"
                  @click="loadSubmissions(id, rank.id)"
                  class="clickable text-right"
                >
                  {{ rank.scores[id] }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="openSubmissions.participant">
            <h2>
              Submissions for
              {{ participants[openSubmissions.participant].name }}:
              {{
                openSubmissions.task
                  ? showRanking.tasks[openSubmissions.task].name
                  : "all submissions"
              }}
            </h2>
            <canvas id="plotcanvas" width="100px" height="200px"></canvas>
            <table class="table mt-3">
              <thead class="thead-dark">
                <tr>
                  <th>Absolute Time</th>
                  <th>Contest Time</th>
                  <th v-if="!openSubmissions.task">Task</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="sub in sortedOpenSubmissions">
                  <td>{{ formatDate(sub.abs_time) }}</td>
                  <td>{{ formatTimeDiff(sub.rel_time * 1000) }}</td>
                  <td v-if="!openSubmissions.task">{{ tasks[sub.task_id].name }}</td>
                  <td>{{ sub.score }}</td>
                </tr>
              </tbody>
            </table>
            <div class="mb-2 text-center">
              <button
                class="mb-2 btn btn-outline-dark"
                @click="closeSubmissions"
              >
                Hide
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MyHeader from "../components/my-header.vue";
import { common_props, formatDate, formatTimeDiff } from "../common";
import { objSize } from "../common";
import { calc_ranking } from "../ranking";
import { fetchAPI } from "../fetch-utils";
import { plot } from "../plotting";

export default {
  components: { MyHeader },
  props: common_props,
  data() {
    return {
      openSubmissions: {},
    };
  },
  methods: {
    objSize,
    formatDate,
    formatTimeDiff,
    loadSubmissions(task, participant, reload) {
      if (!task && this.openContest === "") {
        return;
      }
      if (!reload) {
        if (
          task === this.openSubmissions.task &&
          participant === this.openSubmissions.participant
        ) {
          this.closeSubmissions();
          return;
        }
      }
      var urlbase = task
        ? "/api/task/" + task
        : "/api/contest/" + this.openContest;
      fetchAPI(urlbase + "/submissions/" + participant + "/").then((res) => {
        this.openSubmissions = {
          task: task,
          participant: participant,
          submissions: res,
        };
        setTimeout(this.replot, 250);
      });
    },
    replot() {
      if (this.openSubmissions.participant) {
        plot(
          "plotcanvas",
          this.contestInfo.duration || 0,
          !this.openSubmissions.task,
          this.sortedOpenSubmissions
        );
      }
    },
    closeSubmissions() {
      this.openSubmissions = {};
    },
  },
  watch: {
    openContest(new_val, old_val) {
      this.closeSubmissions();
    },
    contestScores(new_val, old_val) {
      if (this.openSubmissions.task) {
        this.loadSubmissions(
          this.openSubmissions.task,
          this.openSubmissions.participant,
          true
        );
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.replot);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.replot);
  },
  computed: {
    eventRanking() {
      return calc_ranking(
        this.eventScores.scores,
        this.participants,
        this.eventScores.tasks
      );
    },
    contestRanking() {
      return calc_ranking(
        this.contestScores.scores,
        this.participants,
        this.tasks
      );
    },
    showRanking() {
      return this.openContest === "" ? this.eventRanking : this.contestRanking;
    },
    sortedOpenSubmissions() {
      let res = this.openSubmissions.submissions.slice();
      res.sort((a, b) => a.rel_time - b.rel_time);
      return res;
    },
  },
};
</script>
