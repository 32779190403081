import moment from "moment";

export const common_props = {
  userid: Number,
  loggedIn: Boolean,
  username: String,
  admin: Boolean,
  events: Object,
  contests: Object,
  tasks: Object,
  participants: Object,
  openEvent: String,
  openContest: String,
  eventInfo: Object,
  eventScores: Object,
  contestInfo: Object,
  contestScores: Object,
};

export function objSize(obj) {
  var res = 0;
  for (const x in obj) {
    res += 1;
  }
  return res;
}

export function zeroPad(value, length) {
  let str = "";
  for (var i = 0; i < length; i++) {
    str += "0";
  }
  return (str + value).slice(-length);
}

export function formatDate(date) {
  return moment.unix(date).format("D.M.YYYY HH:mm:ss");
}

export function formatTimeDiff(diff) {
  if (!diff) {
    diff = 0;
  }
  if (typeof diff == "number") {
    diff = moment.duration(diff);
  }
  var res = "";
  if (diff.years() > 0) {
    res += diff.years() + " y ";
  }
  if (diff.months() > 0) {
    res += diff.months() + " m ";
  }
  if (diff.days() > 1) {
    res += diff.days() + " days ";
  } else if (diff.days() === 1) {
    res += "1 day ";
  }
  res += zeroPad(diff.hours(), 2);
  res += ":";
  res += zeroPad(diff.minutes(), 2);
  res += ":";
  res += zeroPad(diff.seconds(), 2);
  return res;
}
