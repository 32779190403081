export function calc_ranking(scores, participants, tasks) {
  let res = [];
  if (!scores) {
    return res;
  }
  for (const part in participants) {
    let pres = {};
    pres.id = part;
    pres.name = participants[part].name;
    pres.rank = 0;
    pres.score = 0;
    pres.scores = { ...scores[part] } || {};
    for (const task in tasks) {
      pres.score += pres.scores[task] || 0;
      if (pres.scores[task] === undefined) {
        pres.scores[task] = "-";
      }
    }
    res.push(pres);
  }
  res.sort((a, b) => {
    if (a.score !== b.score) {
      return b.score - a.score;
    }
    return a.name < b.name ? -1 : 1;
  });
  let lastscore = -1;
  let lastrank = 0;
  for (let i = 0; i < res.length; i++) {
    if (res[i].score !== lastscore) {
      lastrank = i + 1;
    }
    res[i].rank = lastrank;
    lastscore = res[i].score;
  }
  return { ranking: res, tasks: tasks };
}

export function calc_bets_ranking(sorting, scores) {
  let res = [];
  if (!scores) {
    return res;
  }
  for (const user in scores.usernames) {
    let pres = {};
    pres.id = user;
    pres.name = scores.usernames[user];
    pres.rank = 0;
    pres.score_event = scores.scores_event ? scores.scores_event[user] || 0 : 0;
    pres.score_ranking = scores.scores[user] || 0;
    pres.score_task = scores.scores_task[user] || 0;
    pres.score = pres.score_ranking + pres.score_task;
    res.push(pres);
  }
  res.sort((a, b) => {
    if (sorting === 0) {
      return b.score - a.score;
    } else if (sorting === 1) {
      return b.score_task - a.score_task;
    } else if (sorting === 2) {
      return b.score_ranking - a.score_ranking;
    } else if (sorting === 3) {
      return b.score_event - a.score_event;
    }
  });
  let lastscore = -1;
  let lastrank = 0;
  for (let i = 0; i < res.length; i++) {
    if (res[i].score !== lastscore) {
      lastrank = i + 1;
    }
    res[i].rank = lastrank;
    lastscore = res[i].score;
  }
  return res;
}
