var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-view", {
    attrs: {
      userid: _vm.userid,
      loggedIn: _vm.loggedIn,
      username: _vm.username,
      admin: _vm.admin,
      events: _vm.events,
      contests: _vm.contests,
      tasks: _vm.tasks,
      participants: _vm.participants,
      openEvent: _vm.openEvent,
      openContest: _vm.openContest,
      eventInfo: _vm.eventInfo,
      eventScores: _vm.eventScores,
      contestInfo: _vm.contestInfo,
      contestScores: _vm.contestScores
    },
    on: {
      openEvent: _vm.setOpenEvent,
      openContest: _vm.setOpenContest,
      loadEvents: _vm.loadEvents,
      loadContests: _vm.loadContests,
      loadTasks: _vm.loadTasks,
      loadParticipants: _vm.loadParticipants,
      logout: _vm.logout
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }