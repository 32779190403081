import "bootstrap/dist/css/bootstrap.min.css";
import "./global-style.css";

import Vue from "vue";
import VueRouter from "vue-router";
import root from "./pages/root.vue";
import scoreboard from "./pages/scoreboard.vue";
import admin from "./pages/admin.vue";
import betting from "./pages/betting.vue";

const root_element = document.createElement("div");
root_element.id = "app";
document.body.appendChild(root_element);

const routes = [
  { path: "", component: scoreboard },
  { path: "/betting", component: betting },
  { path: "/admin", component: admin },
];

const router = new VueRouter({
  routes,
});

Vue.use(VueRouter);

const vm = new Vue({
  router,
  render: (h) => h(root),
  el: "#app",
});
