export function plot(canvid, duration, multi, submissions) {
  const canvas = document.getElementById(canvid);
  canvas.width = canvas.parentElement.clientWidth;
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  if (!duration) {
    duration = 5 * 3600;
    for (sub of submissions) {
      duration = Math.max(duration, sub.rel_time);
    }
  }

  const allColors = [
    "#1b6ff7",
    "#ed2d1c",
    "#fcb141",
    "#20ad0d",
    "#72e5a6",
    "#68b5d8",
    "#af33e0",
    "#c10b97",
  ];
  let taskColors = {};
  if (multi) {
    let idx = 0;
    for (const sub of submissions) {
      if (!taskColors[sub.task_id]) {
        taskColors[sub.task_id] = allColors[idx];
        idx++;
      }
    }
  }

  const border = 10;
  const width = canvas.width - border * 2;
  const height = canvas.height - border * 2;
  const xstep = width / 10;
  const ystep = height / 4;

  ctx.beginPath();
  ctx.strokeStyle = "#EEEEEE";
  for (let i = 0; i < 11; i++) {
    ctx.moveTo(i * xstep + border, 0);
    ctx.lineTo(i * xstep + border, canvas.height);
    ctx.stroke();
  }
  for (let i = 0; i < 5; i++) {
    ctx.moveTo(0, i * ystep + border);
    ctx.lineTo(canvas.width, i * ystep + border);
    ctx.stroke();
  }
  ctx.closePath();
  for (const sub of submissions) {
    ctx.beginPath();
    ctx.fillStyle = multi ? taskColors[sub.task_id] : allColors[0];
    const xoff = (sub.rel_time / duration) * width;
    const yoff = border + (sub.score / 100) * height;
    ctx.fillRect(xoff + border - 2, canvas.height - yoff, 4, yoff);
    ctx.closePath();
  }
}
