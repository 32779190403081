<template>
  <nav class="navbar bg-dark navbar-dark navbar-expand-sm header-bg">
    <router-link class="navbar-brand flex-grow-1" to="/"
      >SOI {{ title }}</router-link
    >
    <div class="navbar-nav mr-2">
      <router-link
        class="nav-item nav-link"
        :class="[title === 'Scoreboard Admin' ? 'active' : '']"
        to="/admin"
        v-if="admin"
        >Admin</router-link
      >
      <router-link
        class="nav-item nav-link"
        :class="[title === 'Scoreboard Betting' ? 'active' : '']"
        to="/betting"
        v-if="loggedIn"
        >Betting</router-link
      >
      <router-link
        class="nav-item nav-link"
        :class="[title === 'Scoreboard' ? 'active' : '']"
        to="/"
        >Scoreboard</router-link
      >
    </div>
    <div class="navbar-text" v-if="loggedIn">
      Hi {{ username }} (<a href="#" @click="$emit('logout')">Logout</a>)
    </div>
    <div v-if="!loggedIn">
      <form method="post" action="/api/login" class="form-inline">
        <button class="btn btn-sm btn-outline-light" type="submit">
          Login
        </button>
      </form>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    title: String,
    username: String,
    admin: Boolean,
    loggedIn: Boolean,
  },
};
</script>
